* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #f8f8f8;
  color: #333; }

a {
  text-decoration: none;
  color: #3482b3; }

ul {
  list-style: none; }

img {
  width: 100%; }

.m-1 {
  margin: 1rem; }

.my-1 {
  margin: 1rem 0; }

.p-1 {
  padding: 1rem; }

.py-1 {
  padding: 1rem 0; }

.m-2 {
  margin: 2rem; }

.my-2 {
  margin: 2rem 0; }

.p-2 {
  padding: 2rem; }

.py-2 {
  padding: 2rem 0; }

.m-3 {
  margin: 3rem; }

.my-3 {
  margin: 3rem 0; }

.p-3 {
  padding: 3rem; }

.py-3 {
  padding: 3rem 0; }

.m-4 {
  margin: 4rem; }

.my-4 {
  margin: 4rem 0; }

.p-4 {
  padding: 4rem; }

.py-4 {
  padding: 4rem 0; }

.m-5 {
  margin: 5rem; }

.my-5 {
  margin: 5rem 0; }

.p-5 {
  padding: 5rem; }

.py-5 {
  padding: 5rem 0; }

.m-negbtm1 {
  margin-bottom: -1rem; }

.m-negbtm2 {
  margin-bottom: -2rem; }

.m-negbtm3 {
  margin-bottom: -3rem; }

.m-negtop1 {
  margin-top: -1rem; }

.m-negtop2 {
  margin-top: -2rem; }

.m-negtop3 {
  margin-top: -3rem; }

.m-top10 {
  margin-top: 10rem; }

.m-top5 {
  margin-top: 5rem; }

.m-top4 {
  margin-top: 4rem; }

.m-top3 {
  margin-top: 3rem; }

.m-top2 {
  margin-top: 2rem; }

.m-top1 {
  margin-top: 1rem; }

.divider {
  left: 50%;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.103); }

.width75 {
  width: 75% !important;
  margin: auto; }

.width50 {
  width: 50% !important;
  margin: auto; }

textarea {
  resize: vertical; }

.pre-wrap {
  white-space: pre-wrap; }

.main-container {
  padding-bottom: 4rem;
  position: relative; }

.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem; }

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem; }

.text-centered {
  text-align: center; }

.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem; }

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem; }

.round-img {
  border-radius: 50%; }

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0; }

.comp-color1 {
  color: #0a4367; }

.comp-color2 {
  color: #404040; }

.text-primary {
  color: #186ea3; }

.text-light {
  color: #d0d0d0; }

.text-neg {
  color: #8d0000; }

.bg-primary {
  background-color: #165a84;
  color: #fff; }

.bg-light {
  background-color: #f4f4f4;
  color: #333;
  border: #ccc 1px solid; }

.bg-extra-light {
  background-color: #f8f8f8;
  color: #333;
  border: #ccc 1px solid; }

.bg-dark {
  background-color: #343a40;
  color: #fff; }

.bg-success {
  background-color: #28a745;
  color: #fff; }

.bg-danger {
  background-color: #dc3545;
  color: #fff; }

.bg-white {
  background-color: #fff;
  color: #333;
  border: #ccc 1px solid; }

.sml-fnt {
  font-size: 0.75rem !important; }

.fit-form {
  width: 70%;
  display: inline-block; }

.text-left {
  text-align: left; }

.comp-font {
  font-family: 'Prime-Light';
  font-size: 1.9rem; }

@font-face {
  font-family: 'Prime-Light';
  src: local("Prime-Light"), url(./fonts/Prime-Light.otf) format("opentype"); }

/* MOBILE NAV HIDE FOR LARGE SCREENS*/
.mobnav {
  display: none; }

.alert {
  position: fixed;
  top: 5%;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  padding: 0.8rem;
  opacity: 0.9;
  background: #f4f4f4;
  color: #333;
  border-radius: 1rem;
  text-align: center;
  z-index: 3; }
  .alert.alert-primary {
    background-color: #165a84;
    color: #fff; }
  .alert.alert-dark {
    background-color: #343a40;
    color: #fff; }
  .alert.alert-success {
    background-color: #28a745;
    color: #fff; }
  .alert.alert-danger {
    background-color: #dc3545;
    color: #fff; }

.required-notice {
  color: red;
  font-size: 0.8rem; }

.post-form-header {
  padding: 0.5rem; }

.table {
  margin-left: auto;
  margin-right: auto; }
  .table th,
  .table td {
    padding: 1rem;
    text-align: center; }
  .table th {
    background: #f4f4f4; }

.table-alt tr:nth-child(even) {
  background-color: #f2f2f264; }

.form-group {
  margin: 1.2rem 0; }

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888; }

.form input[type='text'], .form input[type='email'], .form input[type='password'], .form input[type='date'] {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc; }

.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc; }

.form input[type='submit'] {
  font: inherit; }

.btn {
  display: inline-block;
  background: #343a40;
  color: #ccc;
  padding: 0.4rem 1.3rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 0.5rem;
  outline: none;
  transition: all 0.3s ease-in; }
  .btn.btn-primary {
    background-color: #165a84;
    color: #fff; }
    .btn.btn-primary:hover {
      background: #1d8ad3; }
  .btn.btn-dark {
    background-color: #343a40;
    color: #fff;
    margin: 0rem; }
    .btn.btn-dark:hover {
      background: #3f474e; }
  .btn.btn-light {
    background-color: #f4f4f4;
    color: #333; }
    .btn.btn-light:hover {
      background: #b4b4b4; }
  .btn.btn-success {
    background-color: #28a745;
    color: #fff; }
    .btn.btn-success:hover {
      background: #2dbc4e; }
  .btn.btn-danger {
    background-color: #dc3545;
    color: #fff; }
    .btn.btn-danger:hover {
      background: #e04b59; }
  .btn:hover {
    background: #626d78;
    color: #fff; }
  .btn.btn-act, .btn.btn-act2 {
    background-color: #1b6a9e;
    color: #fff; }
  .btn.btn-act:hover, .btn.btn-act2:hover {
    background: #c4c4c4; }
  .btn.btn-round {
    background-color: #1b6a9e;
    color: #fff;
    border-radius: 1rem; }
    .btn.btn-round:hover {
      background: #41a0de; }

.btn-create-char {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  color: white;
  border-radius: 0.5rem;
  background: #1b6a9e; }
  .btn-create-char:hover {
    background: #c4c4c4; }

.btn-view-chars {
  margin-top: 1rem;
  font-size: 1.25rem;
  color: white;
  border-radius: 0.5rem;
  background: #1b6a9e; }
  .btn-view-chars:hover {
    background: #c4c4c4; }

.btn-char-area {
  margin: 0rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.25rem; }

.btn-more {
  margin-right: -1rem;
  padding: 0.25rem 0.75rem;
  background-color: #00000000;
  color: #333; }
  .btn-more:hover {
    background-color: #00000000;
    color: #1b6a9e; }

.btn-save-fixed {
  position: fixed;
  z-index: 2;
  top: 10%;
  right: -0.65%;
  font-size: 0.75rem;
  padding: 0.5rem; }

.btn-back-fixed {
  position: fixed;
  z-index: 2;
  bottom: 0%;
  right: -0.5%;
  font-size: 0.75rem;
  padding: 0.25rem;
  background: rgba(0, 0, 0, 0.089); }

.btn-act1-fixed {
  position: fixed;
  top: 85%;
  left: 0%;
  font-size: 0.5rem;
  padding: 0.5rem;
  opacity: 0.5; }

.btn-act2-fixed {
  position: fixed;
  top: 90%;
  left: -0.25%;
  font-size: 0.5rem;
  padding: 0.5rem;
  opacity: 0.5; }

.btn-act3-fixed {
  position: fixed;
  top: 95%;
  left: -0.6%;
  font-size: 0.5rem;
  padding: 0.5rem;
  opacity: 0.5; }

.btn-rounded {
  border-radius: 0.25rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in; }

.btn-rounded1 {
  border-radius: 0.25rem;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0.075;
  transition: all 0.3s ease-in; }
  .btn-rounded1:hover {
    opacity: 0.5; }

.btn-add-note {
  border-radius: 0.25rem;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0.25;
  padding: 0.33rem;
  transition: all 0.3s ease-in; }
  .btn-add-note:hover {
    opacity: 1; }

.note-delete-btn {
  display: grid;
  grid-column: 1/4;
  border-radius: 0.25rem;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0.15;
  padding: 0.33rem;
  transition: all 0.3s ease-in; }

.btn-delete-note {
  display: grid;
  grid-column: 1/3;
  margin-left: 30rem;
  margin-right: 30rem;
  color: black;
  padding: 0.4rem 1.3rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease-in;
  background: rgba(86, 86, 86, 0);
  border-radius: 1rem;
  opacity: 0.25;
  margin-top: -0.75rem;
  padding: 0rem;
  transition: all 0.3s ease-in; }
  .btn-delete-note:hover {
    opacity: 1; }

.btn-trash-red {
  margin-top: 1rem;
  font-size: 1rem;
  color: rgba(151, 151, 151, 0.753);
  background: rgba(0, 0, 0, 0); }
  .btn-trash-red:hover {
    font-size: 1.25rem;
    color: #9c0000;
    background: rgba(0, 0, 0, 0); }

.post-buttons {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

/* SIDENAV aka SIDEBAR */
.side-nav {
  position: fixed;
  top: 10%;
  left: 1.75%;
  background: #fff;
  height: 31.5rem;
  width: 14rem;
  z-index: 5;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  transition: width 1s ease-in-out; }
  .side-nav--expanded .side-nav__item-text-container {
    transition: opacity 0.25s ease-in-out;
    transition-delay: 0.75s;
    opacity: 1; }
  .side-nav--expanded .side-nav__max-btn {
    transition: opacity 0.33s ease-in-out;
    opacity: 0; }
  .side-nav--expanded .side-nav__min-btn {
    transition: opacity 1s ease-in-out;
    opacity: 1; }
  .side-nav--collapsed {
    width: 4.25rem; }
  .side-nav--collapsed .side-nav__item-text-container {
    transition: opacity 0.25s ease-in-out;
    opacity: 0; }
  .side-nav--collapsed .side-nav__min-btn {
    transition: opacity 0.33s ease-in-out;
    opacity: 0; }
  .side-nav--collapsed .side-nav__max-btn {
    transition: opacity 1s ease-in-out;
    opacity: 1; }

.side-nav__container {
  display: flex; }

.side-nav__icon-container {
  flex: 0.25;
  padding: 0 0.75rem; }
  .side-nav__icon-container div {
    padding: 1rem 0rem; }

.side-nav__item-text-container {
  flex: 1;
  opacity: 0; }
  .side-nav__item-text-container div {
    padding: 1rem 0rem; }

.side-nav__item-text {
  color: #1b1b1b;
  font-weight: 600;
  font-size: 1rem;
  transition: opacity 0.25s ease-in-out;
  transition-delay: 0.7s; }
  .side-nav__item-text:hover {
    color: #1179be; }

.navicon {
  text-align: center; }
  .navicon a {
    color: #adadad;
    transition: color 0.5s ease-in-out; }
    .navicon a:hover {
      color: #41a0de; }

.side-nav__controls-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 0.5rem;
  margin-bottom: -0.5rem;
  transition: opacity 0.75s ease-in-out;
  opacity: 0.75; }

.side-nav__min-btn {
  padding: 0.5rem;
  font-size: 1.25rem;
  border-radius: 1rem 1rem 0 0;
  color: #adadad6b;
  cursor: pointer; }

.side-nav__max-btn,
.side-nav__close-btn {
  padding: 0.5rem;
  font-size: 1.2rem;
  border-radius: 1rem 1rem 0 0;
  color: #adadad6b;
  cursor: pointer; }

.side-nav__close-btn {
  display: none; }

.side-nav__max-btn {
  margin-right: -1.55rem; }

.side-nav__act-number {
  font-weight: bold;
  font-size: 1rem;
  padding: 0rem 0.75rem;
  text-align: center; }

.side-nav__icon {
  padding: 0rem 0.75rem;
  font-size: 1rem; }

.tooltiptext {
  visibility: hidden;
  width: 70px;
  margin: auto;
  background-color: #115077;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.8em;
  z-index: 1;
  bottom: 101%;
  left: 0%; }

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 0.75; }

.mobnav__itemdesc {
  font-size: 0.5rem;
  text-align: center;
  font-weight: 700; }

.navFixed {
  position: fixed; }

.navTop {
  position: relative; }

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.1rem 2rem;
  z-index: 10;
  width: 100%;
  top: 0;
  border: 0px;
  opacity: 0.9;
  background-color: #f8f8f8; }
  .navbar ul {
    display: flex; }
  .navbar a {
    color: #1b1b1b;
    padding: 0.45rem;
    margin: 0 0.25; }
    .navbar a:hover {
      color: #165a84; }

.company-name {
  margin: 0rem 0rem 0rem 0rem !important; }

.footer {
  border-radius: 0.5rem;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.25rem;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  background-color: rgba(56, 56, 56, 0.025);
  color: white; }
  .footer a:link, .footer a:visited, .footer a:active {
    font-size: 0.66rem;
    color: rgba(0, 0, 0, 0.925); }

.footer-col1 {
  display: grid;
  grid-row: 1;
  grid-column: 1/4; }

.footer-col2 {
  display: grid;
  grid-row: 1;
  grid-column: 1/17; }

.footer-col3 {
  display: grid;
  grid-row: 1;
  grid-column: 13/17; }

.footer-links {
  margin-bottom: 10rem; }

.landing {
  position: relative;
  background: url("./img/showcase.jpg") no-repeat center center/cover;
  height: 115vh; }

.landing-message {
  position: relative;
  color: #333;
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: auto;
  top: 23.5%;
  left: -33.3%;
  text-align: center; }

.landing-buttons {
  margin-top: 0.5rem; }

.para-footer {
  opacity: 1;
  font-size: 2rem; }

.parallax {
  display: flex;
  justify-content: center;
  background: url("./img/landingbg1.jpg");
  z-index: 1;
  margin-top: -0.5rem; }

.landing-bg-a {
  width: 500px;
  height: 500px;
  right: 0%;
  margin-top: 55rem;
  margin-bottom: -45rem;
  background: none;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  opacity: 1;
  z-index: 1; }

.landing-bg-b {
  width: 50%;
  height: 50%;
  margin-top: 20rem;
  left: 5%;
  background: url("./img/extrapaper.png") no-repeat center center/cover;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  opacity: 0.95;
  z-index: 1; }

.landing-content {
  position: absolute;
  display: grid;
  grid-template-areas: 'step1 step2 step3';
  gap: 2.5rem;
  align-content: center;
  justify-content: center;
  z-index: 1;
  width: 75%;
  margin: auto;
  margin-top: 2.5rem;
  border-radius: 3rem;
  padding: 3rem; }

.step-outline {
  background: #f8f8f8;
  padding: 1rem;
  border-radius: 1rem; }

.step1-container {
  grid-area: step1; }

.step2-container {
  grid-area: step2; }

.step3-container {
  grid-area: step3; }

.step1-container,
.step2-container,
.step3-container {
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 1.5s ease-in-out;
  min-height: 450px; }
  .step1-container:hover,
  .step2-container:hover,
  .step3-container:hover {
    transform: scale(1.1);
    background: #0f2027;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2c5364, #203a43, #0f2027);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #fff; }

.step1-title,
.step2-title,
.step3-title {
  background: #012e6936;
  border-radius: 1rem 1rem 0rem 0rem;
  text-align: center;
  font-size: 2rem;
  padding: 1rem 0rem; }

.step-number {
  font-size: 3.75rem; }

.step1-desc,
.step2-desc,
.step3-desc {
  padding: 2rem;
  text-align: center;
  font-weight: 700;
  font-size: 1rem; }

.domore__header {
  text-align: center;
  color: #f8f8f8;
  background: none;
  font-size: 3rem;
  margin-top: -5rem;
  margin-bottom: -2.55rem;
  padding: 0;
  transition: all 1s ease-in-out; }
  .domore__header:hover {
    transform: translateY(3.5rem);
    color: #000; }

.domore__container {
  display: grid;
  grid-template-areas: 'img1 dmdesc1 dmdesc1 dmdesc1' 'dmdesc2 dmdesc2 dmdesc2 img2';
  gap: 7.5rem 1rem;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 600px;
  background: #f8f8f8;
  padding: 5rem 16rem; }

.domore__img1 {
  display: grid;
  text-align: center;
  grid-area: img1;
  width: 350px;
  height: 200px;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 1.5s ease-in-out;
  content: url(https://res.cloudinary.com/beatshopper/image/upload/v1622651738/domoreimg1_lmomot.jpg);
  z-index: 2; }

.domore__img2 {
  display: grid;
  text-align: center;
  grid-area: img2;
  width: 350px;
  height: 200px;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 1.5s ease-in-out;
  content: url(https://res.cloudinary.com/beatshopper/image/upload/v1622432232/domoreimg2_qo6rau.jpg);
  z-index: 2; }

.domore__desc1,
.domore__desc2 {
  z-index: 2; }
  .domore__desc1 h3,
  .domore__desc2 h3 {
    text-align: center;
    padding-bottom: 1rem;
    font-size: 1.5rem; }
  .domore__desc1 p,
  .domore__desc2 p {
    font-size: 1.2rem;
    font-weight: 500; }

.domore__desc1 {
  grid-area: dmdesc1;
  padding: 0 3rem; }

.domore__desc2 {
  grid-area: dmdesc2;
  padding: 0 3rem; }

.landing-footer {
  display: block;
  background: #113347;
  color: #fff;
  padding: 4rem 0 3rem 0;
  margin-top: 4rem; }

.signup-cta {
  padding: 2rem;
  text-align: center; }

.about-text-container {
  padding: 1.25rem;
  background: rgba(136, 136, 136, 0.05);
  border-radius: 2rem; }

.question-info {
  background: rgba(175, 175, 175, 0.082);
  border-radius: 0.25rem 0.25rem 0 0; }
  .question-info small {
    padding: 0.3em 1rem; }

.info-caret {
  text-align: right;
  padding-right: 1.2rem;
  margin-top: -1.5rem;
  color: rgba(0, 0, 0, 0.181); }
  .info-caret :nth-child(2) {
    margin-bottom: -0.1rem;
    margin-right: -1rem; }

.infocareticon {
  margin-top: -1.45rem; }

.help-text {
  font-size: 0.66rem;
  color: rgba(0, 0, 0, 0.181); }

.act-output {
  padding: 0rem 2rem;
  width: 75%;
  margin: auto;
  border-radius: 0.5rem;
  background: #f9f9f94f;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05); }

.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  gap: 2rem;
  align-items: center;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 2px 0 rgba(0, 0, 0, 0.005);
  border-radius: 10rem; }

.profile-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'top abt' 'wrk wrk' 'amz amz';
  gap: 1rem; }

.bg-prof-img {
  background: #f1f1f110; }

.profile-top {
  border-radius: 4rem 0rem 0rem 0rem;
  padding: 0rem;
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.05); }
  .profile-top img {
    width: 200px; }
  .profile-top .icons a {
    color: #fff;
    margin: 0 0.3rem; }
    .profile-top .icons a:hover {
      color: #343a40; }

.profile-about {
  border-radius: 0em 4rem 0rem 0rem;
  grid-area: abt;
  text-align: center;
  background: rgba(41, 41, 41, 0.945);
  color: white; }
  .profile-about .genres {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }

.author-link {
  padding: 1rem 0rem;
  margin: 0rem;
  margin: auto;
  max-width: 75%;
  word-wrap: break-word; }

.profile-works {
  grid-area: wrk; }
  .profile-works > div {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: #ccc 1px dotted; }
    .profile-works > div:last-child {
      border: none; }
    .profile-works > div p {
      margin: 0.5rem 0; }

.profile-amazon {
  grid-area: amz; }

.profile-more-btn {
  color: white;
  background: #8c8c8c;
  padding: 0.25rem 0.75rem;
  margin-top: -1rem;
  border-radius: 1rem; }

.authorpage {
  display: flex; }

#finished-works-header {
  margin-top: 5rem; }

.progress-exp-text {
  margin-top: -2.2rem;
  color: #1a1a1a62;
  font-size: 0.75rem; }

.outline-progress {
  margin: 0% 25%; }

.story-area {
  position: relative;
  padding: 1rem 0rem; }

.create-area {
  margin-top: 0rem;
  margin-bottom: 1rem; }

.start-area {
  padding: 6em 0rem;
  display: hidden; }

.progress-light-grey {
  color: #000;
  background-color: #3939391d; }

.progress-round {
  border-radius: 4px; }

.progress-container {
  padding: 0.01em 16px; }

.progress-blue {
  color: #fff;
  font-size: 0.75rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-color: #1b6a9eee; }

.compl-perc-text {
  color: #ffffff; }

.char-name-link {
  color: black; }
  .char-name-link:hover {
    color: #0017c2; }

.story-area-prompt {
  position: relative;
  background: url("./img/story-area-prompt.png");
  background-repeat: no-repeat;
  background-size: cover; }

.prompt-hidden {
  background: rgba(255, 255, 255, 0); }

.prompt-close {
  position: relative;
  z-index: 5; }

.close-prompt-text {
  font-size: 0.6rem;
  position: absolute;
  height: 100%;
  top: 100px;
  left: 1.75%;
  opacity: 0.25;
  display: none;
  z-index: 7; }

.close-prompt-x {
  font-size: 1.1rem;
  position: absolute;
  left: 17.5%;
  margin-top: 6.2rem;
  border: none;
  transition: all 0.3s ease-in;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(0, 0, 0, 0.075);
  cursor: pointer; }
  .close-prompt-x:hover {
    color: rgba(0, 0, 0, 0.25); }

.close-prompt-x:hover {
  color: rgba(0, 0, 0, 0.25); }

.char-photo-prev {
  max-width: 50%;
  height: auto; }

.char-container {
  padding: 1rem 0rem 0rem 0rem;
  width: 100%;
  margin: auto; }

.characters {
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem; }

.character-card {
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 2rem;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.0025);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.1); }

.char-card-delete {
  text-align: right;
  grid-column: 3;
  grid-row: 1;
  margin-top: -0.5rem; }

.char-card-addNote {
  z-index: 4;
  text-align: center;
  grid-column: 2;
  grid-row: 7;
  margin-top: -0rem; }

.char-card-more {
  grid-column: 1/4;
  grid-row: 8;
  opacity: 0.75; }

.char-card-full {
  width: 100%;
  margin: auto; }

.char-card-delete-text {
  opacity: 0.75;
  display: none; }

.char-info {
  margin-right: 65%;
  grid-column: 1 / 2;
  grid-row: 2;
  background-color: rgba(59, 71, 141, 0.05);
  border-radius: 1rem 1rem 0rem 0rem; }

.char-name {
  font-size: 1.1rem; }

.char-desc {
  grid-column: 1 / 4;
  grid-row: 6;
  background-color: rgba(0, 0, 0, 0);
  padding: 1rem;
  margin-top: 0rem;
  z-index: 1; }

.char-img {
  grid-column: 1 / 4;
  grid-row: 3;
  background-color: rgba(0, 0, 0, 0); }
  .char-img img {
    border-radius: 0rem 1rem 1rem 1rem;
    z-index: 2; }

.char-notes p {
  color: black; }

.protag-tag {
  font-size: 0.66rem;
  background: black;
  margin-top: -0.5rem;
  margin-left: 10rem;
  margin-right: 10rem;
  padding: 0rem;
  grid-column: 2;
  grid-row: 5;
  background-color: rgba(59, 71, 141, 0.05);
  border-radius: 0rem 0rem 3rem 3rem; }

.full-size-protag {
  margin-left: 25rem;
  margin-right: 25rem; }

.char-notes {
  display: grid;
  grid-column: 1 / 4;
  grid-row: 9;
  background-color: rgba(0, 0, 0, 0); }

.note-text {
  /* display: inline-flex; */
  /* flex-direction: row; */
  padding: 0.25rem; }

.full-user-text {
  white-space: pre-wrap;
  /* text-align: left; */
  line-height: 1.5rem;
  width: 100%; }
  .full-user-text p {
    padding-top: 0.5rem; }

.short-desc-text {
  white-space: pre-wrap; }

.note-sugg-card {
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 15%;
  margin: auto;
  background: rgba(0, 0, 0, 0.033);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 1px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem; }

.posts-header {
  padding: 0.5rem;
  border: none;
  margin: auto;
  background: #f2f2f298; }

.post {
  display: grid;
  border-radius: 0.25rem;
  grid-template-columns: 1fr 4fr;
  gap: 1rem;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.001); }
  .post > div:first-child {
    text-align: center; }
  .post img {
    width: 150px; }

.comment {
  display: grid;
  border-radius: 2rem;
  grid-template-columns: 1fr 4fr;
  gap: 1rem;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.001); }

.post-text {
  text-align: center;
  display: grid;
  grid-row: 2 / 3;
  grid-column: 1 / 9; }

.post-like {
  display: grid;
  grid-row: 4;
  grid-column: 1; }

.post-unlike {
  display: grid;
  grid-row: 4;
  grid-column: 3/5; }

.post-liked {
  background: cornflowerblue; }

.post-like-full {
  width: 50%;
  margin: auto;
  display: grid;
  grid-row: 5;
  grid-column: 4 / 6; }

.post-unlike-full {
  display: grid;
  grid-row: 5;
  grid-column: 5/7; }

.post-comments {
  display: grid;
  grid-row: 4;
  grid-column: 7 / 9; }

.post-text-full {
  text-align: center;
  margin: 1rem 0rem;
  white-space: pre-wrap; }

.comment-vote {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  position: relative; }

.vote-btn {
  border-radius: 2rem;
  display: grid;
  width: 50%;
  margin: auto; }

.vote-btn-container {
  display: grid;
  grid-column: 4/6; }

.vote-icon {
  display: grid;
  grid-row: 1;
  z-index: 2;
  max-width: 100%;
  height: auto; }

.vote-icon-img {
  width: 10%; }

.vote-count {
  color: #1b6a9e;
  display: grid;
  z-index: 3;
  grid-row: 1;
  font-size: 1.5rem; }

.comment-report {
  text-align: right;
  margin-top: -1rem;
  grid-column: 1/9; }

.comment-report-btn {
  z-index: 5;
  grid-column: 5;
  padding: 0rem;
  opacity: 0.25;
  font-size: 0.75rem;
  background: rgba(255, 0, 0, 0); }
  .comment-report-btn:hover {
    color: red;
    opacity: 1;
    background: rgba(255, 0, 0, 0); }
    .comment-report-btn:hover + .comment-report-text {
      visibility: visible; }

.comment-report-text {
  visibility: hidden;
  z-index: 1;
  margin-right: 1.5rem;
  text-align: right;
  margin-top: -1.1rem;
  display: grid;
  grid-column: 4/9;
  grid-row: 1;
  font-size: 0.5rem;
  opacity: 0.5; }

.post-report {
  text-align: right;
  margin-top: -1rem;
  grid-column: 1/9; }

.report-btn {
  z-index: 5;
  grid-column: 9;
  padding: 0rem;
  opacity: 0.25;
  font-size: 0.75rem;
  background: rgba(255, 0, 0, 0); }
  .report-btn:hover {
    color: red;
    opacity: 1;
    background: rgba(255, 0, 0, 0); }

.report-text {
  visibility: hidden;
  z-index: 1;
  margin-right: 1.5rem;
  text-align: right;
  margin-top: -1.1rem;
  display: grid;
  grid-column: 4/9;
  grid-row: 1;
  font-size: 0.5rem;
  opacity: 0.5; }

.report-btn:hover + .report-text {
  visibility: visible; }

.post-date {
  text-align: center;
  display: grid;
  grid-row: 3;
  grid-column: 1/ 9; }

.post-delete {
  display: grid;
  grid-row: 5;
  grid-column: 4/6; }

.comments-btn {
  margin-right: 0rem; }

.comment-count {
  background-color: white;
  color: black;
  border-radius: 0.5rem;
  padding: 0.2rem 0.33rem; }

.verify-container {
  padding: 0.5rem;
  width: 40%;
  margin: auto;
  background: rgba(255, 255, 255, 0.605);
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 1px 0 rgba(0, 0, 0, 0.1); }

.verify-notice {
  color: #e6e6e6;
  border-radius: 0.25rem;
  background: #0a4367;
  padding: 0.5rem;
  font-size: 1.25rem; }

@media (max-width: 1674px) {
  .step1-container p,
  .step2-container p,
  .step3-container p {
    font-size: 0.9rem; } }

@media (max-width: 1599px) {
  .domore__container p {
    font-size: 0.95rem; }
  .step1-container:hover,
  .step2-container:hover,
  .step3-container:hover {
    transform: scale(1.13); } }

@media (max-width: 1499px) {
  .step1-container:hover,
  .step2-container:hover,
  .step3-container:hover {
    transform: scale(1.1); }
  .domore__container {
    grid-template-areas: 'img1' 'dmdesc1' 'img2' 'dmdesc2';
    gap: 2.5rem 1rem;
    width: 100%;
    padding: 4rem 2rem;
    margin: auto;
    padding-top: 6rem;
    margin-bottom: 20em; }
  .parallax {
    background: #0f3e5b;
    height: 150vh; }
  .landing-content {
    grid-template-areas: 'step1' 'step2' 'step3';
    width: 90%;
    margin: auto;
    padding: 0rem; }
  .step1-container,
  .step2-container,
  .step3-container {
    min-height: 0px; }
    .step1-container p,
    .step2-container p,
    .step3-container p {
      font-size: 1.25rem; } }

@media (max-width: 1099px) {
  /* MOBILE NAV */
  .mobnav {
    z-index: 5;
    position: fixed;
    left: 0rem;
    bottom: -2rem;
    width: 100%;
    height: 10vh;
    margin: auto;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0rem 1rem;
    border-radius: 6rem 6rem 0rem 0rem;
    margin-bottom: 2rem;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.025), 0.1em 0.1em 1em rgba(0, 0, 0, 0.2); }
  .mobnav__item {
    padding: 1rem 0rem; }
  .mobnav__itemlink {
    padding: 1rem;
    color: #dadada;
    font-size: 1.5rem; }
  .navbar ul li {
    padding: 0 0.5rem; }
  .side-nav {
    display: none; }
  .alert {
    position: fixed;
    top: 91%; }
  .hide-sm {
    display: none; }
  .sml-scrn-text {
    font-size: 0.525rem; }
  .container {
    margin-top: 8rem; }
  .landing {
    height: 100vh; }
  .landing-message {
    width: 75%;
    margin: auto;
    top: 69%;
    left: 0%;
    color: #e0e0e0; }
    .landing-message h1 {
      font-size: 1.75rem; }
    .landing-message p {
      margin-top: -0.75rem;
      font-size: 0.95rem; }
  .parallax {
    background: #0f3e5b;
    height: 165vh; }
  .landing-buttons {
    margin-top: 0.33rem; }
  .landing-bg-a {
    width: 200px; }
  .domore__header {
    font-size: 0.9rem;
    background: none;
    padding: 0;
    color: black;
    z-index: 4;
    transition: all 1s ease-in-out; }
    .domore__header:hover {
      transform: translateY(-3rem);
      color: #fff; }
  .domore__container {
    display: grid;
    grid-template-areas: 'img1' 'dmdesc1' 'img2' 'dmdesc2';
    width: 100%;
    padding: 4rem 2rem;
    margin: auto;
    margin-bottom: 47.5rem; }
  .domore__img1 {
    width: 85%;
    height: 100%; }
  .domore__img2 {
    width: 85%;
    height: 100%; }
  .domore__desc1 {
    padding: 1rem; }
  .domore__desc2 {
    padding: 1rem; }
  .landing-content {
    grid-template-areas: 'step1' 'step2' 'step3';
    width: 90%;
    margin: auto;
    padding: 0rem; }
  .landing-footer {
    padding: 2rem 0;
    display: block; }
  .btn-delete-note {
    margin-left: 7rem;
    margin-right: 7rem; }
  .landing {
    position: relative;
    background: url("./img/showcase.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 57.5% 77.5%; }
  .x-large {
    font-size: 2.5rem; }
  .large {
    font-size: 1.75rem; }
  .lead {
    font-size: 1rem; }
  .navbar {
    display: block;
    text-align: center; }
    .navbar ul {
      text-align: center;
      justify-content: center; }
    .navbar h1 {
      margin-bottom: 1rem; }
  .dash-buttons a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
    background-color: #f4f4f4;
    color: #333; }
  .profile {
    grid-template-columns: 1fr;
    text-align: center; }
    .profile ul {
      display: none; }
  .profile-grid {
    grid-template-areas: 'top' 'abt' 'wrk' 'amz'; }
  .profile-about .genres {
    flex-direction: column; }
  .post,
  .comment {
    grid-template-columns: 1fr; }
  .post a,
  .post button {
    padding: 0.3rem 0.4rem; }
  .post-like {
    display: grid;
    grid-row: 4;
    grid-column: 1/5; }
  .dash-exp-text {
    font-size: 0.9rem; }
  .btn-save-fixed {
    top: 13%;
    right: -2%; }
  .btn-back-fixed {
    bottom: 0%;
    right: -2%; }
  .story-area-prompt {
    background: rgba(255, 255, 255, 0); }
  .prompt-close {
    display: none; }
  .act-text {
    font-size: 0.5rem;
    padding: 0rem 0.55rem; }
  .act-2-text {
    font-size: 0.5rem;
    padding: 0rem 1.75rem; }
  .act-area {
    margin-right: -2.5rem;
    margin-left: -2rem; }
  .char-info {
    margin-right: 40%;
    grid-column: 1 / 4;
    grid-row: 2; }
  .char-desc {
    grid-column: 1 / 4;
    grid-row: 6;
    border-radius: 0.5rem; }
  .char-img img {
    border-radius: 0rem 0rem 0.5rem 0.5rem; }
  .protag-tag {
    font-size: 0.8rem;
    margin-left: 4rem;
    margin-right: 4rem;
    padding: 0rem 2rem 0rem 2rem;
    border-radius: 0rem 0rem 5rem 5rem; }
  .char-container {
    width: 90%; }
  .short-desc-text {
    width: 100%;
    font-size: 0.75rem;
    display: block;
    line-height: 1rem; }
  .full-user-text {
    font-size: 0.75rem; }
  .characters {
    grid-template-columns: repeat(1, 1fr); }
  .post-comments {
    display: grid;
    grid-row: 4;
    grid-column: 5 / 9; }
  .vote-btn-container {
    grid-column: 2/8; }
  .outline-progress {
    margin: 0%; }
  .vote-icon-img {
    width: 25%; }
  .report-text {
    margin-right: 2.75rem;
    visibility: visible;
    font-size: 0.5rem;
    opacity: 0.25; }
  .comment-report {
    grid-column: 1; }
  .footer-col1 {
    grid-column: 1/9; }
  .footer-col3 {
    grid-column: 9/17; }
  .note-sugg-card {
    width: 80%; }
  .landing-bg-b {
    margin-top: 27.5rem;
    width: 75%;
    height: 75%; }
  .parallax {
    background-position: 55% 0%; }
  .verify-container {
    width: 100%; }
  .profile-grid {
    grid-template-columns: repeat(1, 1fr); }
  .profile-top {
    border-radius: 10rem 10rem 0rem 0rem; }
  .profile-about {
    border-radius: 0rem; }
  .prof-img {
    margin-top: 0rem; }
  .act-output {
    padding: 0rem 0.5rem;
    width: 100%; } }

@media (max-width: 1325px) and (min-width: 1101px) {
  .landing-message {
    left: -35%; }
    .landing-message p {
      font-size: 1rem; }
    .landing-message h1 {
      margin-top: 3rem;
      font-size: 2rem; }
  .note-sugg-card {
    width: 15%; } }

@media (min-width: 801px) and (max-width: 1100px) {
  .landing-message {
    top: 70%;
    left: 0%;
    width: 40%;
    color: #fff; }
    .landing-message p {
      font-size: 1.25rem; }
    .landing-message h1 {
      margin-top: 2rem;
      font-size: 3rem; }
  .outline-progress {
    margin: 0% 15%; }
  .note-sugg-card {
    width: 15%; } }

@media (max-width: 2000px) and (min-width: 1100px) {
  .btn.btn-act2 {
    background-color: #1b6a9e;
    color: #fff; }
  .btn-act-1 {
    margin-left: 0.5rem; }
  .act-text {
    padding: 0rem 2rem; }
  .act-2-text {
    padding: 0rem 4rem; }
  .act-area {
    padding: 2rem;
    position: relative;
    background: url("./img/actbackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 57.5% 77.5%; } }

@media (max-width: 700px) {
  .parallax {
    height: 285vh; }
  .landing-footer {
    margin-top: -10rem; }
  .step1-container:hover,
  .step2-container:hover,
  .step3-container:hover {
    transform: scale(1.14); }
  .step1-container p,
  .step2-container p,
  .step3-container p {
    font-size: 1rem; } }

@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 800px) {
  .landing-buttons {
    display: none; }
  .landing-bg-a {
    margin-top: 125rem;
    margin-bottom: -52em; }
  .parallax {
    background-position: 25% 5%;
    height: 400vh; }
  .domore__container {
    gap: 0;
    margin-bottom: 22.5rem; }
  .domore__img1,
  .domore__img2 {
    width: auto;
    height: 150px; } }
